import { parseWithZod } from '@conform-to/zod'
import { invariantResponse } from '@epic-web/invariant'
import {
	type ActionFunctionArgs,
	type HeadersFunction,
	json,
	type LinksFunction,
	type LoaderFunctionArgs,
	type MetaFunction,
} from '@remix-run/node'
import {
	Form,
	Link,
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	useFetchers,
	useLoaderData,
	useLocation,
	useMatches,
	useSubmit,
} from '@remix-run/react'
import { withSentry } from '@sentry/remix'
import {
	Book,
	Car,
	CreditCard,
	FileText,
	Menu,
	Plug,
	PlusCircle,
	Search,
	Map,
} from 'lucide-react'
import { useEffect, useRef } from 'react'
import { ClientOnly } from 'remix-utils/client-only'
import { ExternalScripts } from 'remix-utils/external-scripts'
import { HoneypotProvider } from 'remix-utils/honeypot/react'
import { z } from 'zod'

import CrispChat from '#app/components/crisp.js'
// import Logo from '#app/components/logo.tsx'
import { Separator } from '#app/components/ui/separator.tsx'
import {
	Sheet,
	SheetClose,
	SheetContent,
	SheetHeader,
	SheetTitle,
	SheetTrigger,
} from '#app/components/ui/sheet.tsx'

import * as gtag from '#app/utils/gtags.client.ts'
import { GeneralErrorBoundary } from './components/error-boundary.tsx'
import { Footer } from './components/footer.tsx'
// import { SearchBar } from './components/search-bar.tsx'
import { useToast } from './components/toaster.tsx'
import { Button } from './components/ui/button.tsx'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuPortal,
	DropdownMenuTrigger,
} from './components/ui/dropdown-menu.tsx'
import { GlobalLoading } from './components/ui/global-loading.tsx'
import { href as iconsHref, Icon } from './components/ui/icon.tsx'
import { EpicToaster } from './components/ui/sonner.tsx'
import { ThemeSwitchMobile } from './routes/resources+/theme-switch-mobile.tsx'
import { ThemeSwitch, useTheme } from './routes/resources+/theme-switch.tsx'
import fontStyleSheetUrl from './styles/font.css?url'
import tailwindStyleSheetUrl from './styles/tailwind.css?url'
import { authenticator, getUserId } from './utils/auth.server.ts'
import { ClientHintCheck, getHints } from './utils/client-hints.tsx'
import { prisma } from './utils/db.server.ts'
import { getEnv } from './utils/env.server.ts'
// import { FathomScript, useTrackPageview } from './utils/fathom.tsx'
import { honeypot } from './utils/honeypot.server.ts'
import { combineHeaders, getDomainUrl, getUserImgSrc } from './utils/misc.tsx'
import { useNonce } from './utils/nonce-provider.ts'
import { getTheme, setTheme, type Theme } from './utils/theme.server.ts'
import { makeTimings, time } from './utils/timing.server.ts'
import { getToast } from './utils/toast.server.ts'
import { useOptionalUser, useUser } from './utils/user.ts'

export const links: LinksFunction = () => {
	return [
		// Preload svg sprite as a resource to avoid render blocking
		{ rel: 'preload', href: iconsHref, as: 'image' },
		// Preload CSS as a resource to avoid render blocking
		{ rel: 'preload', href: fontStyleSheetUrl, as: 'style' },
		{ rel: 'mask-icon', href: '/favicons/mask-icon.svg' },
		{
			rel: 'alternate icon',
			type: 'image/png',
			href: '/favicons/favicon-32x32.png',
		},
		{ rel: 'apple-touch-icon', href: '/favicons/apple-touch-icon.png' },
		{
			rel: 'manifest',
			href: '/site.webmanifest',
			crossOrigin: 'use-credentials',
		} as const, // necessary to make typescript happy
		//These should match the css preloads above to avoid css as render blocking resource
		{ rel: 'icon', type: 'image/svg+xml', href: '/favicons/favicon.svg' },
		{ rel: 'stylesheet', href: fontStyleSheetUrl },
		{ rel: 'stylesheet', href: tailwindStyleSheetUrl },
	].filter(Boolean)
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
	return [
		{ title: data ? 'Carregados' : 'Erro | Carregados' },
		{ name: 'description', content: `Your own captain's log` },
	]
}

export async function loader({ request }: LoaderFunctionArgs) {
	const timings = makeTimings('root loader')
	const userId = await time(() => getUserId(request), {
		timings,
		type: 'getUserId',
		desc: 'getUserId in root',
	})

	const user = userId
		? await time(
				() =>
					prisma.user.findUniqueOrThrow({
						select: {
							id: true,
							name: true,
							username: true,
							image: { select: { id: true } },
							roles: {
								select: {
									name: true,
									permissions: {
										select: { entity: true, action: true, access: true },
									},
								},
							},
							carSubscriptions: { select: { maker: true, model: true } },
							favorites: { select: { carId: true } },
							favoriteStations: { select: { locationId: true } },
							credits: true,
						},
						where: { id: userId },
					}),
				{ timings, type: 'find user', desc: 'find user in root' },
			)
		: null
	if (userId && !user) {
		console.info('something weird happened')
		// something weird happened... The user is authenticated but we can't find
		// them in the database. Maybe they were deleted? Let's log them out.
		await authenticator.logout(request, { redirectTo: '/' })
	}
	const { toast, headers: toastHeaders } = await getToast(request)
	const honeyProps = honeypot.getInputProps()

	return json(
		{
			user,
			requestInfo: {
				hints: getHints(request),
				origin: getDomainUrl(request),
				path: new URL(request.url).pathname,
				userPrefs: {
					theme: getTheme(request),
				},
			},
			ENV: getEnv(),
			toast,
			honeyProps,
			gaTrackingId: process.env.GA_TRACKING_ID,
		},
		{
			headers: combineHeaders(
				{ 'Server-Timing': timings.toString() },
				toastHeaders,
			),
		},
	)
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
	const headers = {
		'Server-Timing': loaderHeaders.get('Server-Timing') ?? '',
	}
	return headers
}

const ThemeFormSchema = z.object({
	theme: z.enum(['system', 'light', 'dark']),
})

export async function action({ request }: ActionFunctionArgs) {
	const formData = await request.formData()
	const submission = parseWithZod(formData, {
		schema: ThemeFormSchema,
	})

	invariantResponse(submission.status === 'success', 'Invalid theme received')

	const { theme } = submission.value

	const responseInit = {
		headers: { 'set-cookie': setTheme(theme) },
	}
	return json({ result: submission.reply() }, responseInit)
}

function Document({
	children,
	nonce,
	theme = 'light',
	env = {},
	gaTrackingId,
}: {
	children: React.ReactNode
	nonce: string
	theme?: Theme
	env?: Record<string, string>
	gaTrackingId?: string
}) {
	return (
		<html lang="pt" className={`light h-full overflow-x-hidden`}>
			<head>
				<ClientHintCheck nonce={nonce} />
				<Meta />
				<meta charSet="utf-8" />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover"
				/>
				<meta
					name="theme-color"
					// content={`${theme === 'dark' ? '#020817' : '#fbfaf8'}`}
					content="#000000"
				/>
				<Links />
			</head>
			<body className="bg-background text-foreground">
				{!gaTrackingId ? null : (
					<>
						<script
							async
							src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
							nonce={nonce}
						/>
						<script
							async
							id="gtag-init"
							nonce={nonce}
							dangerouslySetInnerHTML={{
								__html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${gaTrackingId}', {
                  page_path: window.location.pathname,
                });
              `,
							}}
						/>
					</>
				)}
				<GlobalLoading />
				{children}
				<script
					nonce={nonce}
					dangerouslySetInnerHTML={{
						__html: `window.ENV = ${JSON.stringify(env)}`,
					}}
				/>
				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
				{/* <FathomScript nonce={nonce} data-site={env.FATHOM_ANALYTICS_SITE_ID} /> */}
				<ClientOnly fallback={''}>{() => <CrispChat />}</ClientOnly>
				<ExternalScripts />
			</body>
		</html>
	)
}

function App() {
	const data = useLoaderData<typeof loader>()
	const nonce = useNonce()
	const user = useOptionalUser()
	const theme = useTheme()
	const matches = useMatches()
	const location = useLocation()
	const isOnCarrosPage = matches.find(m => m.id === 'routes/carros+/index')
	const isOnDashboardPage = matches.find(
		m => m.id === 'routes/usuario+/$username_+/carros',
	)
	const isOnCarroPage = matches.find(m => m.id === 'routes/carros+/$carId')
	const isOnEstacoesPage = matches.find(m => m.id === 'routes/estacoes')
	const isOnMapaPage = matches.find(m => m.id === 'routes/_marketing+/mapa')

	const { gaTrackingId } = data

	// useTrackPageview()
	useToast(data.toast)

	useEffect(() => {
		if (gaTrackingId?.length) {
			gtag.pageview(location.pathname, gaTrackingId)
		}
	}, [location, gaTrackingId])

	return (
		<Document
			nonce={nonce}
			theme={theme}
			env={data.ENV}
			gaTrackingId={gaTrackingId}
		>
			<div className="flex h-full flex-col justify-between bg-[#fbfaf8] dark:bg-muted/30">
				<header
					className={`${
						isOnCarroPage || isOnEstacoesPage || isOnMapaPage
							? 'static bg-[#fbfaf8] dark:bg-background'
							: 'sticky bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60'
					} top-0 z-50 flex justify-center border-b border-border/40 px-6 sm:px-10`}
				>
					<nav
						className={`flex w-full items-center justify-between md:px-6 ${isOnMapaPage ? 'py-1' : 'py-4'}`}
					>
						<Link
							prefetch="intent"
							className="flex items-center gap-1 bg-transparent"
							to="/"
						>
							<img
								className="aspect-square h-10 w-10"
								src="/img/logo.webp"
								alt="Logo Carregados"
							/>
							<div className="font-bold">| carregados</div>
						</Link>
						<div className="hidden items-center space-x-10 text-sm font-semibold text-muted-foreground md:flex">
							<Link
								className="hover:text-primary hover:underline"
								prefetch="intent"
								to="/carros"
							>
								Carros
							</Link>
							{user ? (
								<Link
									className="hover:text-primary hover:underline"
									to={`/usuario/${user.username}/carros/novo`}
								>
									Anunciar
								</Link>
							) : (
								<Link
									className="hover:text-primary hover:underline"
									prefetch="intent"
									to="/login"
								>
									Anunciar
								</Link>
							)}
							{/* <Link
								className="hover:text-primary hover:underline"
								to="/#utilidades"
							>
								Utilidades
							</Link> */}
							{/* <Link
								className="hover:text-primary hover:underline"
								to="/estacoes"
							>
								Estações de Carregamento
							</Link> */}
							<Link className="hover:text-primary hover:underline" to="/mapa">
								Mapa de Estações
							</Link>
						</div>

						<div className="hidden items-center gap-4 md:flex">
							{/* <ThemeSwitch userPreference={data.requestInfo.userPrefs.theme} /> */}
							{user ? (
								<UserDropdown />
							) : (
								<Button
									asChild
									className="bg-[#f58b14]"
									variant="default"
									size="sm"
								>
									<Link prefetch="intent" to="/login">
										Entrar
									</Link>
								</Button>
							)}
						</div>

						<Sheet>
							<SheetTrigger className="block md:hidden" aria-label="Abrir menu">
								<Menu size={30} />
							</SheetTrigger>
							<SheetContent onOpenAutoFocus={(e) => e.preventDefault()}>
								<SheetHeader>
									<SheetTitle>Menu</SheetTitle>
									{/* <SheetDescription>
										This action cannot be undone. This will permanently delete
										your account and remove your data from our servers.
									</SheetDescription> */}
								</SheetHeader>
								<div className="flex h-full flex-col space-y-8 overflow-auto px-6 pt-10 text-left font-bold">
									{user ? (
										<>
											<div className="flex flex-col gap-4">
												<div className="flex items-center gap-2">
													<img
														className="h-8 w-8 rounded-full object-cover"
														alt={user.name ?? user.username}
														src={getUserImgSrc(user.image?.id)}
													/>
													<span className="flex flex-col gap-1">
														<span className="text-body-sm font-bold">
															{user.name ?? user.username}
														</span>
														<span className="text-xs text-muted-foreground">
															Créditos: {user.credits}
														</span>
													</span>
												</div>
												<Separator />
												<div className="flex flex-col space-y-6 font-semibold">
													<SheetClose asChild>
														<Link
															prefetch="intent"
															className="flex items-center gap-1 hover:text-primary/60"
															to={`/usuario/${user.username}/carros`}
														>
															<Icon
																size="lg"
																className="hover:text-primary/60"
																name="pencil-2"
															>
																Meus Anúncios
															</Icon>
														</Link>
													</SheetClose>
													<SheetClose asChild>
														<Link
															prefetch="intent"
															className="flex items-center gap-1 hover:text-primary/60"
															to={`/usuario/${user.username}/carros/novo`}
														>
															<PlusCircle />
															Criar Anúncio
														</Link>
													</SheetClose>
													<SheetClose asChild>
														<Link
															className="flex items-center gap-1 hover:text-primary/60"
															to={`/usuario/${user.username}/carros/creditos`}
														>
															<CreditCard />
															Comprar Créditos
														</Link>
													</SheetClose>
													<SheetClose asChild>
														<Link
															prefetch="intent"
															className="flex items-center gap-1 hover:text-primary/60"
															to={`/usuario/${user.username}/carros/compras`}
														>
															<FileText />
															Minhas compras
														</Link>
													</SheetClose>
													<SheetClose asChild>
														<Link
															prefetch="intent"
															className="flex items-center gap-1 hover:text-primary/60"
															to={`/usuario/${user.username}`}
														>
															<Icon name="avatar" size="lg">
																Minha conta
															</Icon>
														</Link>
													</SheetClose>
													<Form action="/logout" method="POST">
														<Icon
															size="lg"
															className="hover:text-primary/60"
															name="exit"
														>
															<button type="submit">Sair</button>
														</Icon>
													</Form>
												</div>
											</div>
										</>
									) : (
										<SheetClose asChild>
											<Link
												prefetch="intent"
												className="flex w-full items-center"
												to="/login"
											>
												<Button variant="default" className="w-full font-bold">
													Entrar
												</Button>
											</Link>
										</SheetClose>
									)}
									<div className="flex flex-col gap-4 pt-6">
										<div className="flex items-center gap-1">
											<span className="text-body-sm font-bold">Navegação</span>
										</div>
										<Separator />
										<div className="flex flex-col space-y-6 font-semibold">
											<SheetClose asChild>
												<Link
													className="flex items-center gap-1 hover:text-primary/60"
													to="/carros"
												>
													<Search />
													Procurar Carro
												</Link>
											</SheetClose>
											{user ? (
												<SheetClose asChild>
													<Link
														prefetch="intent"
														className="flex items-center gap-1 hover:text-primary/60"
														to={`/usuario/${user.username}/carros/novo`}
													>
														<Car />
														Anunciar
													</Link>
												</SheetClose>
											) : (
												<SheetClose asChild>
													<Link
														prefetch="intent"
														className="flex items-center gap-1 hover:text-primary/60"
														to="/login"
													>
														<Car />
														Anunciar
													</Link>
												</SheetClose>
											)}
											<SheetClose asChild>
												{/* <Link
													prefetch="intent"
													className="flex items-center gap-1 hover:text-primary/60"
													to="/#utilidades"
												>
													<Book />
													Utilidades
												</Link> */}
											</SheetClose>
											{/* <SheetClose asChild>
												<Link
													prefetch="intent"
													className="flex items-center gap-1 hover:text-primary/60"
													to="/estacoes"
												>
													<Plug />
													Estações
												</Link>
											</SheetClose> */}
											<SheetClose asChild>
												<Link
													prefetch="intent"
													className="flex items-center gap-1 hover:text-primary/60"
													to="/mapa"
												>
													<Map />
													Mapa
												</Link>
											</SheetClose>
										</div>
										{/* <div className="flex w-full justify-center rounded bg-primary mb-6 p-2 text-background">
											<ThemeSwitchMobile
												userPreference={data.requestInfo.userPrefs.theme}
											/>
										</div> */}
									</div>
								</div>
							</SheetContent>
						</Sheet>
					</nav>
				</header>

				<div className="flex-1">
					<Outlet />
				</div>

				{/* <div
					className={`container flex h-80 justify-between bg-muted/50 dark:bg-muted/30 pb-5 ${
						isOnCarrosPage || isOnDashboardPage ? 'hidden' : ''
					}`}
				>
					<Link to="/">
						<div className="font-light">epic</div>
					</Link>
					<ThemeSwitch userPreference={data.requestInfo.userPrefs.theme} />
				</div> */}
				<div
					className={`${isOnCarrosPage || isOnMapaPage || isOnDashboardPage ? 'hidden' : ''}`}
				>
					<Footer />
				</div>
			</div>
			<EpicToaster closeButton position="top-center" theme={theme} />
		</Document>
	)
}

function AppWithProviders() {
	const data = useLoaderData<typeof loader>()
	return (
		<HoneypotProvider {...data.honeyProps}>
			<App />
		</HoneypotProvider>
	)
}

export default withSentry(AppWithProviders)

function UserDropdown() {
	const user = useUser()
	const submit = useSubmit()
	const formRef = useRef<HTMLFormElement>(null)
	return (
		<DropdownMenu modal={false}>
			<DropdownMenuTrigger asChild>
				<Button asChild variant="secondary">
					<Link
						prefetch="intent"
						to={`/usuario/${user.username}`}
						// this is for progressive enhancement
						onClick={e => e.preventDefault()}
						className="flex items-center gap-2"
					>
						<img
							className="h-8 w-8 rounded-full object-cover"
							alt={user.name ?? user.username}
							src={getUserImgSrc(user.image?.id)}
						/>
						<span className="text-body-sm font-bold">
							{user.name ? user.name.split(' ')[0] : user.username}
						</span>
					</Link>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuPortal>
				<DropdownMenuContent sideOffset={8} align="start">
					<DropdownMenuItem asChild>
						<Link prefetch="intent" to={`/usuario/${user.username}`}>
							<Icon className="text-body-md" name="avatar">
								Minha conta
							</Icon>
						</Link>
					</DropdownMenuItem>
					<DropdownMenuItem asChild>
						<Link prefetch="intent" to={`/usuario/${user.username}/carros`}>
							<Icon className="text-body-md" name="pencil-2">
								Anúncios
							</Icon>
						</Link>
					</DropdownMenuItem>
					<DropdownMenuItem
						asChild
						// this prevents the menu from closing before the form submission is completed
						onSelect={event => {
							event.preventDefault()
							submit(formRef.current)
						}}
					>
						<Form action="/logout" method="POST" ref={formRef}>
							<Icon className="text-body-md" name="exit">
								<button type="submit">Sair</button>
							</Icon>
						</Form>
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenuPortal>
		</DropdownMenu>
	)
}

/**
 * @returns the user's theme preference, or the client hint theme if the user
 * has not set a preference.
 */
// export function useTheme() {
// 	const hints = useHints()
// 	const requestInfo = useRequestInfo()
// 	const optimisticMode = useOptimisticThemeMode()
// 	if (optimisticMode) {
// 		return optimisticMode === 'system' ? hints.theme : optimisticMode
// 	}
// 	return requestInfo.userPrefs.theme ?? hints.theme
// }

/**
 * If the user's changing their theme mode preference, this will return the
 * value it's being changed to.
 */
export function useOptimisticThemeMode() {
	const fetchers = useFetchers()
	const themeFetcher = fetchers.find(f => f.formAction === '/')

	if (themeFetcher && themeFetcher.formData) {
		const submission = parseWithZod(themeFetcher.formData, {
			schema: ThemeFormSchema,
		})

		if (submission.status === 'success') {
			return submission.value.theme
		}
	}
}

// function ThemeSwitch({
// 	formName,
// 	userPreference,
// }: {
// 	formName: string
// 	userPreference?: Theme | null
// }) {
// 	const fetcher = useFetcher<typeof action>()

// 	const [form] = useForm({
// 		id: formName,
// 		lastResult: fetcher.data?.result,
// 	})

// 	const optimisticMode = useOptimisticThemeMode()
// 	const mode = optimisticMode ?? userPreference ?? 'system'
// 	const nextMode =
// 		mode === 'system' ? 'light' : mode === 'light' ? 'dark' : 'system'
// 	const modeLabel = {
// 		light: (
// 			<Icon name="sun">
// 				<span className="sr-only">Light</span>
// 			</Icon>
// 		),
// 		dark: (
// 			<Icon name="moon">
// 				<span className="sr-only">Dark</span>
// 			</Icon>
// 		),
// 		system: (
// 			<Icon name="laptop">
// 				<span className="sr-only">System</span>
// 			</Icon>
// 		),
// 	}

// 	return (
// 		<fetcher.Form method="POST" {...getFormProps(form)}>
// 			<input type="hidden" name="theme" value={nextMode} />
// 			<div className="flex gap-2">
// 				<button
// 					type="submit"
// 					className="flex h-8 w-8 cursor-pointer items-center justify-center"
// 				>
// 					{modeLabel[mode]}
// 				</button>
// 			</div>
// 		</fetcher.Form>
// 	)
// }

export function ErrorBoundary() {
	// the nonce doesn't rely on the loader so we can access that
	const nonce = useNonce()

	// NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
	// likely failed to run so we have to do the best we can.
	// We could probably do better than this (it's possible the loader did run).
	// This would require a change in Remix.

	// Just make sure your root route never errors out and you'll always be able
	// to give the user a better UX.

	return (
		<Document nonce={nonce}>
			<GeneralErrorBoundary />
		</Document>
	)
}
